"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItsTaskGroupType = void 0;
var ItsTaskGroupType;
(function (ItsTaskGroupType) {
    ItsTaskGroupType["FCIF"] = "FCIF";
    ItsTaskGroupType["QUALS"] = "QUALS";
    ItsTaskGroupType["ACTIV"] = "ACTIV";
    ItsTaskGroupType["STATUS"] = "STATUS";
    ItsTaskGroupType["PHYSICAL"] = "PHYSICAL";
    ItsTaskGroupType["CURRENCY"] = "CURRENCY";
    ItsTaskGroupType["PROFICIENCY"] = "PROFICIENCY";
})(ItsTaskGroupType = exports.ItsTaskGroupType || (exports.ItsTaskGroupType = {}));
