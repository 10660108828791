"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PointOfContact = void 0;
const point_of_contact_schema_1 = require("./point-of-contact.schema");
const enums_1 = require("../../../enums");
const base_model_1 = require("../../base-model");
class PointOfContact extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            firstName: 'John',
            lastName: 'Smith',
            email: 'user@ploflyt.com',
            phone: 55555555,
            aircrewType: enums_1.AircrewPositionType.External,
            duyPosition: 'MD',
            specificationRoles: [],
            role: enums_1.RoleType.AircrewMember,
        };
    }
    getSchema() {
        return point_of_contact_schema_1.pointOfContactSchema;
    }
}
exports.PointOfContact = PointOfContact;
