"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.aircraftSchema = void 0;
const yup = require("yup");
exports.aircraftSchema = yup.object().shape({
    id: yup.string().notRequired(),
    type: yup.string().required(),
    squadronId: yup.string().notRequired(),
    engineNumber: yup.number().required(),
});
