"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserCertificationsMock = void 0;
const uuid_1 = require("uuid");
const user_certifications_model_1 = require("./user-certifications.model");
const certification_event_status_1 = require("../certification-event-status");
class UserCertificationsMock extends user_certifications_model_1.UserCertifications {
    constructor(data) {
        const seedData = Object.assign({ userId: (0, uuid_1.v4)(), certificationEventStatusList: [...Array(Math.floor(Math.random() * 10) + 1)].map(() => new certification_event_status_1.CertificationEventStatusMock()) }, data);
        super(seedData);
    }
}
exports.UserCertificationsMock = UserCertificationsMock;
