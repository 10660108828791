"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AircraftProfile = void 0;
const aircraft_profile_schema_1 = require("./aircraft-profile.schema");
const base_model_1 = require("../../base-model");
class AircraftProfile extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            type: '',
            engineNumber: 0,
        };
    }
    getSchema() {
        return aircraft_profile_schema_1.aircraftProfileSchema;
    }
}
exports.AircraftProfile = AircraftProfile;
