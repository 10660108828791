"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LetterXMock = void 0;
const uuid_1 = require("uuid");
const letter_x_model_1 = require("./letter-x.model");
const user_certifications_mock_1 = require("./user-certifications/user-certifications.mock");
class LetterXMock extends letter_x_model_1.LetterX {
    constructor(data) {
        const seedData = Object.assign({ id: (0, uuid_1.v4)(), squadronId: (0, uuid_1.v4)(), aircrewTypeId: (0, uuid_1.v4)(), createdAt: Date.now(), updatedAt: Date.now(), userCertificationsList: [...Array(Math.floor(Math.random() * 10) + 1)].map(() => new user_certifications_mock_1.UserCertificationsMock()) }, data);
        super(seedData);
    }
}
exports.LetterXMock = LetterXMock;
