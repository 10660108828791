"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.letterXSchema = void 0;
const yup = require("yup");
const user_certifications_1 = require("./user-certifications");
exports.letterXSchema = yup.object().shape({
    id: yup.string().optional(),
    createdAt: yup.number().optional().default(Date.now()),
    updatedAt: yup.number().optional().default(Date.now()),
    squadronId: yup.string().required(),
    aircrewTypeId: yup.string().required(),
    userCertificationsList: yup.array().of(user_certifications_1.userCertificationsSchema).required(),
});
