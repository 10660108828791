"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterOperator = exports.Operator = void 0;
var Operator;
(function (Operator) {
    Operator["EQUAL"] = "==";
    Operator["NOT_EQUAL"] = "!=";
    Operator["GT"] = ">";
    Operator["G_EQUAL_T"] = ">=";
    Operator["LT"] = "<";
    Operator["L_EQUAL_T"] = "=<";
    Operator["CONTAINS"] = "CONTAINS";
    Operator["NOT_CONTAINS"] = "NOT_CONTAINS";
    Operator["IN"] = "IN";
    Operator["ELEMENT_MATCH"] = "ELEMENT_MATCH";
    Operator["EXISTS"] = "EXISTS";
})(Operator = exports.Operator || (exports.Operator = {}));
class FilterOperator {
    constructor(value) {
        this.value = value;
    }
    static fromValue(value) {
        switch (value) {
            case Operator.EQUAL:
                return new FilterOperator(Operator.EQUAL);
            case Operator.NOT_EQUAL:
                return new FilterOperator(Operator.NOT_EQUAL);
            case Operator.GT:
                return new FilterOperator(Operator.GT);
            case Operator.G_EQUAL_T:
                return new FilterOperator(Operator.G_EQUAL_T);
            case Operator.LT:
                return new FilterOperator(Operator.LT);
            case Operator.L_EQUAL_T:
                return new FilterOperator(Operator.L_EQUAL_T);
            case Operator.CONTAINS:
                return new FilterOperator(Operator.CONTAINS);
            case Operator.NOT_CONTAINS:
                return new FilterOperator(Operator.NOT_CONTAINS);
            case Operator.IN:
                return new FilterOperator(Operator.IN);
            case Operator.ELEMENT_MATCH:
                return new FilterOperator(Operator.ELEMENT_MATCH);
            case Operator.EXISTS:
                return new FilterOperator(Operator.EXISTS);
            default:
                throw new Error(`The filter operator ${value} is invalid`);
        }
    }
    isPositive() {
        return this.value !== Operator.NOT_EQUAL && this.value !== Operator.NOT_CONTAINS;
    }
    throwErrorForInvalidValue(value) {
        throw new Error(`The filter operator ${value} is invalid`);
    }
    static equal() {
        return this.fromValue(Operator.EQUAL);
    }
}
exports.FilterOperator = FilterOperator;
