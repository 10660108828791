"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LetterX = void 0;
const base_model_1 = require("../base-model");
const letter_x_schema_1 = require("./letter-x.schema");
class LetterX extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            createdAt: Date.now(),
            updatedAt: Date.now(),
            userCertificationsList: [],
        };
    }
    getSchema() {
        return letter_x_schema_1.letterXSchema;
    }
}
exports.LetterX = LetterX;
