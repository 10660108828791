"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificationEventStatusMock = void 0;
const uuid_1 = require("uuid");
const certification_event_status_model_1 = require("./certification-event-status.model");
class CertificationEventStatusMock extends certification_event_status_model_1.CertificationEventStatus {
    constructor(data) {
        const seedData = Object.assign({ certificationEventId: (0, uuid_1.v4)(), marked: Math.random() > 0.5 }, data);
        super(seedData);
    }
}
exports.CertificationEventStatusMock = CertificationEventStatusMock;
