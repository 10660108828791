"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AircraftDetail = void 0;
const base_model_1 = require("../../base-model");
const aircraft_detail_schema_1 = require("./aircraft-detail.schema");
const enums_1 = require("../../../enums");
class AircraftDetail extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            status: enums_1.AircraftStatusType.FMC,
            profileId: '',
            notes: '',
            location: '',
            onbrdFuel: '',
            nextEventFuel: '',
            nextEventDate: '',
        };
    }
    getSchema() {
        return aircraft_detail_schema_1.aircraftDetailSchema;
    }
}
exports.AircraftDetail = AircraftDetail;
