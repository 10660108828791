"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.aircraftDetailSchema = void 0;
const yup = require("yup");
const enums_1 = require("../../../enums");
const utilities_1 = require("../../../utilities");
exports.aircraftDetailSchema = yup.object().shape({
    id: yup.string().notRequired(),
    profileId: yup.string().required(),
    status: yup.string().oneOf((0, utilities_1.getEnumValues)(enums_1.AircraftStatusType)).notRequired(),
    notes: yup.string().notRequired(),
    location: yup.string().notRequired(),
    onbrdFuel: yup.string().notRequired(),
    nextEventFuel: yup.string().notRequired(),
    nextEventDate: yup.string().notRequired(),
    squadronId: yup.string().notRequired(),
});
