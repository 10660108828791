"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.itsTaskGroupSchema = void 0;
const yup = require("yup");
const its_task_schema_1 = require("../its-task/its-task.schema");
const utilities_1 = require("../../utilities");
const enums_1 = require("../../enums");
exports.itsTaskGroupSchema = yup.object().shape({
    id: yup.string().notRequired(),
    groupName: yup.string().required(),
    eventType: yup.string().required().oneOf((0, utilities_1.getEnumValues)(enums_1.CalendarEventTypes)).required(),
    aircrewTypeId: yup.string().required(),
    squadronId: yup.string().required(),
    groupType: yup.string().required(),
    tasks: yup.array().of(its_task_schema_1.itsTaskSchema).required(),
});
