"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItsTaskGroupMock = void 0;
const uuid_1 = require("uuid");
const its_task_group_model_1 = require("./its-task-group.model");
const enums_1 = require("../../enums");
const its_task_mock_1 = require("../its-task/its-task.mock");
class ItsTaskGroupMock extends its_task_group_model_1.ItsTaskGroup {
    constructor(data) {
        const seedData = Object.assign({ id: (0, uuid_1.v4)(), groupName: 'Takeoff', squadronId: (0, uuid_1.v4)(), eventType: enums_1.CalendarEventTypes.FLIGHT, aircrewTypeId: (0, uuid_1.v4)(), groupType: enums_1.ItsTaskGroupType.CURRENCY, tasks: [
                new its_task_mock_1.ItsTaskMock(),
                new its_task_mock_1.ItsTaskMock(),
                new its_task_mock_1.ItsTaskMock(),
                new its_task_mock_1.ItsTaskMock(),
                new its_task_mock_1.ItsTaskMock(),
            ] }, data);
        super(seedData);
    }
}
exports.ItsTaskGroupMock = ItsTaskGroupMock;
