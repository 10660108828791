"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserCertifications = void 0;
const base_model_1 = require("../../base-model");
const user_certifications_schema_1 = require("./user-certifications.schema");
class UserCertifications extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            certificationEventStatusList: [],
        };
    }
    getSchema() {
        return user_certifications_schema_1.userCertificationsSchema;
    }
}
exports.UserCertifications = UserCertifications;
