"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pointOfContactSchema = void 0;
const yup = require("yup");
const utilities_1 = require("../../../utilities");
const enums_1 = require("../../../enums");
exports.pointOfContactSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
    phone: yup.number().notRequired(),
    aircrewType: yup.string().required(),
    dutyPosition: yup.string().notRequired(),
    specificationRoles: yup
        .array()
        .of(yup.string().oneOf((0, utilities_1.getEnumValues)(enums_1.SpecificationRoleType)))
        .notRequired(),
    role: yup.string().oneOf((0, utilities_1.getEnumValues)(enums_1.RoleType)).notRequired(),
});
