"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificationEventMock = void 0;
const uuid_1 = require("uuid");
const certification_event_model_1 = require("./certification-event.model");
class CertificationEventMock extends certification_event_model_1.CertificationEvent {
    constructor(data) {
        const seedData = Object.assign({ name: `Certification Event ${Math.random().toString(36).substring(2, 15)}`, squadronId: (0, uuid_1.v4)(), aircrewTypeId: (0, uuid_1.v4)() }, data);
        super(seedData);
    }
}
exports.CertificationEventMock = CertificationEventMock;
