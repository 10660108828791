"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.certificationEventSchema = void 0;
const yup = require("yup");
exports.certificationEventSchema = yup.object().shape({
    id: yup.string().optional(),
    name: yup.string().required(),
    squadronId: yup.string().required(),
    aircrewTypeId: yup.string().required(),
});
