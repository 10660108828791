"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Aircraft = void 0;
const base_model_1 = require("../base-model");
const aircraft_schema_1 = require("./aircraft.schema");
class Aircraft extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            type: '',
            engineNumber: 0,
        };
    }
    getSchema() {
        return aircraft_schema_1.aircraftSchema;
    }
}
exports.Aircraft = Aircraft;
