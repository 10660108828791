"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.aircraftProfileSchema = exports.engineHoursSchema = void 0;
const yup = require("yup");
exports.engineHoursSchema = yup.object().shape({
    engine: yup.string().required(),
    hours: yup.number().required(),
});
exports.aircraftProfileSchema = yup.object().shape({
    id: yup.string().notRequired(),
    tailNumber: yup.string().required(),
    unitChargedFlyHours: yup.string().required(),
    aircraftType: yup.string().required(),
    aircraftModel: yup.string().required(),
    airframeHours: yup.number().required(),
    cycles: yup.number().notRequired(),
    engineNumber: yup.number().notRequired(),
    engines: yup.array().of(exports.engineHoursSchema).notRequired(),
    squadronId: yup.string().notRequired(),
});
